// deduction available
import HTTP from "@/axios";

export default {
  getAccounts() {
    return new Promise((resolve, reject) => {
      HTTP.get("accounts/")
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
