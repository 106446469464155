<template>
  <div class="filter-list">
    <Button
      :key="option.value"
      v-for="option in options"
      :theme="
        localSeeAll
          ? 'secondary'
          : this.computedValues.indexOf(option.value) !== -1
          ? 'primary'
          : 'secondary'
      "
      size="small"
      style="min-height: 32px; height: initial"
      @click="() => toggleValue(option.value)"
      >{{ option.label }}</Button
    >
    <Button
      theme="tertiary"
      size="medium"
      style="min-height: 32px; height: initial"
      @click="seeAll"
      >TOUT AFFICHER</Button
    >
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button";
import _ from "lodash";
export default {
  name: "FilterList",
  components: { Button },
  props: ["options", "values"],
  data() {
    return {
      localSeeAll: true,
    };
  },
  methods: {
    toggleValue(value) {
      if (this.localSeeAll) {
        _.remove(this.computedValues, (item) => item !== value);
        if (this.computedValues.indexOf(value) === -1) {
          this.computedValues.push(value);
        }
        this.localSeeAll = false;
        return;
      }

      if (this.computedValues.indexOf(value) !== -1) {
        _.remove(this.computedValues, (item) => item === value);
      } else {
        this.computedValues.push(value);
      }
    },
    seeAll() {
      this.localSeeAll = true;
      _.each(this.options, (option) => {
        if (this.computedValues.indexOf(option.value) === -1) {
          this.computedValues.push(option.value);
        }
      });
    },
  },
  computed: {
    computedValues: {
      get: function () {
        return this.values;
      },
      set: function (newOptions) {
        this.$emit("update", newOptions);
      },
    },
  },
};
</script>

<style scoped>
.filter-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0;
  gap: 8px;
  overflow: auto;
  /*
  flex-wrap: wrap;
   */
}
</style>
