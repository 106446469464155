<template>
  <Workspace>
    <a-alert
      style="margin-bottom: 10px"
      message="Cette page affiche uniquement les comptes dont vous êtes le titulaire. Vous souhaitez ajouter un compte ? Contactez nous !"
      type="info"
      show-icon
    >
      <template #icon>
        <Icon
          style="width: 20px"
          color="rgba(45, 49, 109, 1)"
          size="thin"
          name="info"
        />
      </template>
    </a-alert>
    <div>
      <Overline>Total des actifs</Overline>
    </div>
    <Heading4
      >{{ total === null ? "" : formatAmount(countTotal) }}
      <Caption v-if="mostRecentDate !== null"
        ><span class="hidden-mobile">Dernière mise à jour : </span
        >{{ this.mostRecentDate }}</Caption
      ></Heading4
    >

    <div style="margin-top: 24px">
      <Overline>Sélectionnez les actifs à afficher</Overline>
      <FilterList :options="filterOptions" v-model:values="filterValues" />
    </div>

    <div class="container-grid">
      <Card v-for="customerProduct in filteredItems" :key="customerProduct._id">
        <div class="customer-product">
          <div
            class="bloc aligner"
            :style="{ background: getColorByType(customerProduct.type) }"
          >
            <Icon
              color="white"
              size="medium"
              :name="getIconByType(customerProduct.type)"
            ></Icon>
          </div>
          <div class="right">
            <Heading6
              class="text-ellipsis"
              style="color: var(--kermony-office-bleu-1)"
              >{{ customerProduct.name }}</Heading6
            >

            <Subtitle1>{{
              formatAmount(customerProduct.amount, { cents: false })
            }}</Subtitle1>
          </div>
        </div>
      </Card>
    </div>
  </Workspace>
</template>

<script>
import AccountApi from "@/api/account";
import moment from "moment-timezone";

import Workspace from "@/components/Workspaces/Workspace";
import Overline from "@/components/Texts/Overline";
import Heading4 from "@/components/Texts/Heading4";
import Caption from "@/components/Texts/Caption";
import FilterList from "@/components/Filters/FilterList";
import Card from "@/components/Cards/Card";
import Heading6 from "@/components/Texts/Heading6";
import Subtitle1 from "@/components/Texts/Subtitle1";
import Icon from "@/components/Icons/Icon";
import { formatAmount } from "@/utils/number";
import _ from "lodash";

export default {
  name: "MyFinancialAssets",
  components: {
    Icon,
    Subtitle1,
    Heading6,
    Card,
    FilterList,
    Caption,
    Heading4,
    Overline,
    Workspace,
  },
  data() {
    return {
      total: null,
      mostRecentDate: null,
      customerProducts: null,
      filterOptions: [
        {
          value: "Comptes bancaires",
          label: "Comptes bancaires",
        },
        {
          value: "Comptes d'épargne",
          label: "Comptes d’épargne",
        },
        {
          value: "Comptes d'investissement",
          label: "Comptes d’investissement",
        },
        {
          value: "Assurance Vie",
          label: "Assurance-vie",
        },
        {
          value: "Epargne Salariale/Retraite",
          label: "Epargne Salariale/Retraite",
        },
        {
          value: "Contrat de capitalisation",
          label: "Contrat de capitalisation",
        },
      ],
      filterValues: [
        "Comptes bancaires",
        "Comptes d'épargne",
        "Comptes d'investissement",
        "Assurance Vie",
        "Epargne Salariale/Retraite",
        "Contrat de capitalisation",
      ],
    };
  },
  computed: {
    filteredItems() {
      return _.filter(this.customerProducts, (item) => {
        return this.filterValues.indexOf(item.category) !== -1;
      });
    },
    countTotal() {
      const arr = _.filter(this.customerProducts, (item) => {
        return this.filterValues.indexOf(item.category) !== -1;
      });
      return _.sumBy(arr, "amount");
    },
  },
  mounted() {
    this.$store.commit("updateHeader", {
      title: "Mes Actifs Financiers",
      subtitle:
        "Le futur est incertain, ne pas placer tous ses fonds dans un même panier",
    });

    this.loadData();
  },

  methods: {
    formatAmount,
    async loadData() {
      this.customerProducts = await AccountApi.getAccounts();

      // Count total
      this.total = _.chain(this.customerProducts)
        .sumBy(({ amount }) => amount)
        .value();

      const mostRecentDate = _.chain(this.customerProducts)
        .map(({ exportDate }) => exportDate)
        .uniq()
        .sortBy()
        .last()
        .value();

      moment.locale("fr");
      const before = moment(mostRecentDate);

      this.mostRecentDate = before.fromNow();
    },
    getColorByType(kind) {
      switch (kind) {
        case "Compte courant":
          return "#71c8da";
        case "Compte titres":
          return "#919bc0";
        case "Assurance":
          return "#003770";
      }
      return "#6d2d68";
    },
    getIconByType(kind) {
      switch (kind) {
        case "Compte courant":
          return "money";
        case "Assurance":
          return "umbrella";
      }
      return "financial";
    },
  },
};

// Type
/*
"Article 83",
"Assurance",
"CEL",
"Compte courant",
"Compte en nominatif",
"Compte titres",
"Compte à terme",
"Contrat de capitalisation",
"LDDS",
"Livret",
"Livret A",
"PEA",
"PEA numéraire",
"PEE",
"PEP assurance",
"PER",
"PERCO",
"PERP"
 */
</script>

<style scoped>
/* Mobile only */
@media only screen and (pointer: coarse) and (min-device-width: 320px) and (max-device-width: 480px) {
  .container-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}

.container-grid {
  margin-top: 12px;
  grid-gap: 16px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  display: grid;
}

.customer-product {
  padding: 16px;
  gap: 8px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto minmax(0, 1fr);
}

.customer-product .bloc {
  width: 49px;
  height: 49px;
  box-shadow: 0px 2px 12px rgba(33, 36, 78, 0.1);
  border-radius: 4px;
}

.customer-product .right {
  display: grid;
}
</style>
